import { StatusFilter } from './data-filters.model'

export type StatusState<TOperations> = {
  [key in keyof TOperations]: number
}

export function getDefaultDocumentStatusCountState<
  T extends object,
  L extends StatusState<T>
>(statuses: T): L {
  return Object.values(statuses).reduce(
    (acc: L, status: keyof L) => ({ ...acc, [status]: 0 }),
    {} as L
  )
}

export const setDocumentsStatusCountState = (
  response: GetDocumentsStatusesResponse
) =>
  [...response].reduce(
    (prev, curr) => ({
      ...prev,
      [curr.Status]: curr.total
    }),
    getDefaultDocumentStatusCountState(StatusFilter)
  )

export type GetDocumentsStatusesResponse = {
  Status: string
  total: number
}[]
