import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_TYPES_DESCRIPTIONS
} from '../+state/documents.constants'
import { CuratedFileList } from '../domain/curated-file-list.model'

export function filterByDocumentType(files: File[]): CuratedFileList {
  const allowedFiles = files.filter(file =>
    ALLOWED_FILE_TYPES.includes(file.type)
  )
  const notAllowedFiles = files.filter(
    file => !ALLOWED_FILE_TYPES.includes(file.type)
  )

  const allowedFilesDescriptions = ALLOWED_FILE_TYPES_DESCRIPTIONS.join(', ')

  return {
    files: allowedFiles,
    errors: notAllowedFiles.map(file => ({
      filename: file.name,
      message: `${file.name} should be ${allowedFilesDescriptions} format. Please convert it and try again.`
    }))
  }
}
