import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  DOCUMENTS_FEATURE_KEY,
  DocumentsState,
  documentsAdapter
} from './documents.reducer'

export const selectDocumentsState = createFeatureSelector<DocumentsState>(
  DOCUMENTS_FEATURE_KEY
)

const { selectAll, selectEntities } = documentsAdapter.getSelectors()

export const selectLoading = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => state.loading
)

export const selectFilters = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => state.filters
)

export const selectTotalCount = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => state.totalCount
)

export const selectDocumentsEntities = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => selectEntities(state)
)

export const selectSelectedId = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => state.selectedId
)

export const selectEntity = createSelector(
  selectDocumentsEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)

export const selectAllDocuments = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => selectAll(state)
)

export const selecFilters = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => state.filters
)

export const selectDocumentStatusesCount = createSelector(
  selectDocumentsState,
  (state: DocumentsState) => state.documentsStatusesCount
)
