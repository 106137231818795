import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  exhaustMap,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs'
import { fromGetDocumentsResponse } from '../adapter/FromGetDocumentsResponse'
import { AsyncOperations } from '../domain/document-loading.model'
import { DocumentsService } from '../infrastructure/documents.service'
import { documentsActions } from './documents.actions'
import {
  AWAITING_DOCUMENT_DIALOG_ID,
  DELETE_DOCUMENT_DIALOG_ID
} from './documents.constants'
import { DocumentsFacade } from './documents.facade'
import { StatusFilter } from '../domain/data-filters.model'

@Injectable()
export class DocumentsEffects {
  private actions$ = inject(Actions)
  private service = inject(DocumentsService)
  private dialog = inject(Dialog)

  private alertsFacade = inject(LegacyAlertsFacade)
  private readonly documentFacade = inject(DocumentsFacade)

  loadDocumentsStatusCount = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.loadDocumentsStatusesCount),
      switchMap(() =>
        forkJoin([
          this.service.getUnmatchedSubmissionsV2({
            status: StatusFilter.Unmatched
          }),
          this.service.getUnmatchedSubmissionsV2({
            status: StatusFilter.Awaiting
          })
        ]).pipe(
          map(response =>
            documentsActions.loadDocumentsStatusesCountSuccess({
              documentsStatusesCount: {
                [StatusFilter.Unmatched]: response[0].totalRecords,
                [StatusFilter.Awaiting]: response[1].totalRecords
              }
            })
          ),
          catchError(error =>
            of(documentsActions.loadDocumentsStatusesCountFail({ error }))
          )
        )
      )
    )
  )

  loadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.loadDocuments),
      switchMap(({ filters }) =>
        this.service.getUnmatchedSubmissionsV2(filters).pipe(
          map(response => ({
            documents: fromGetDocumentsResponse(response),
            count: response.totalRecords
          })),
          switchMap(({ documents, count }) => {
            return [
              documentsActions.loadDocumentsSuccess({ documents }),
              documentsActions.setTotalCount({ count })
            ]
          }),
          catchError(error => of(documentsActions.loadDocumentsFail({ error })))
        )
      )
    )
  )

  loadDocumentSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.loadSubmission),
      switchMap(({ id }) =>
        this.service
          .getUnmatchedSubmissionsV2({
            uuid: id,
            itemsPerPage: 1
          })
          .pipe(
            map(response => fromGetDocumentsResponse(response)[0]),
            map(document =>
              documentsActions.loadSubmissionSuccess({ document })
            ),
            catchError(error =>
              of(documentsActions.loadSubmissionFail({ error }))
            ),
            finalize(() =>
              this.documentFacade.endLoading(AsyncOperations.getSubmission)
            )
          )
      )
    )
  )

  loadInvoiceDetailsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentsActions.loadSubmissionFail),
        tap(error => console.error(error)),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load submission."
          })
        })
      ),
    { dispatch: false }
  )

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.deleteDocument),
      exhaustMap(({ id }) =>
        this.service.deleteDocument(id).pipe(
          map(() => documentsActions.deleteDocumentSuccess()),
          catchError(({ error }) =>
            of(documentsActions.deleteDocumentFail({ error }))
          )
        )
      )
    )
  )

  deleteDocumentOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.deleteDocumentSuccess),
      tap(() => this.dialog.getDialogById(DELETE_DOCUMENT_DIALOG_ID)?.close()),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Email has been deleted successfully.`,
          alertType: 'success'
        })
      }),
      withLatestFrom(this.documentFacade.filters$),
      switchMap(([, filters]) => [
        documentsActions.loadDocuments({ filters }),
        documentsActions.loadDocumentsStatusesCount()
      ])
    )
  )

  deleteDocumentOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentsActions.deleteDocumentFail),
        tap(({ error }) => {
          if (typeof error === 'string') {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: error
            })
          } else {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: "There's an error trying to delete email."
            })
          }
        })
      ),
    { dispatch: false }
  )

  awaitingDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.awaitingDocument),
      exhaustMap(({ id }) =>
        this.service.awaitDocument(id).pipe(
          map(() => documentsActions.awaitingDocumentSuccess()),
          catchError(({ error }) =>
            of(documentsActions.awaitingDocumentFail({ error }))
          )
        )
      )
    )
  )

  awaitingDocumentOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.awaitingDocumentSuccess),
      tap(() =>
        this.dialog.getDialogById(AWAITING_DOCUMENT_DIALOG_ID)?.close()
      ),
      tap(() => {
        this.alertsFacade.addAlert({
          label: 'Email has been moved to Awaiting Invoice successfully',
          alertType: 'success'
        })
      }),
      withLatestFrom(this.documentFacade.filters$),
      switchMap(([, filters]) => [
        documentsActions.loadDocuments({ filters }),
        documentsActions.loadDocumentsStatusesCount()
      ])
    )
  )

  awaitingDocumentOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentsActions.awaitingDocumentFail),
        tap(({ error }) => {
          if (typeof error === 'string') {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: error
            })
          } else {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: `There's an error trying to move to Awaiting Invoice`
            })
          }
        })
      ),
    { dispatch: false }
  )
}
