import { LoadingStatuses } from '@navix/shared/loading'
import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DataFilters, StatusFilter } from '../domain/data-filters.model'
import { AsyncOperations } from '../domain/document-loading.model'
import { Document } from '../domain/document.model'
import { StatusState } from '../domain/get-documents-status-state'

export const documentsActions = createActionGroup({
  source: 'Documents',
  events: {
    'set loading': props<{
      operation: AsyncOperations
      loading: LoadingStatuses
      message?: string
    }>(),
    'set selected id': props<{ selectedId: string }>(),
    'set selected document': props<{ document: any }>(),
    'get selected document': props<{ document: any }>(),
    'load documents': props<{ filters: DataFilters }>(),
    'load documents success': props<{ documents: Document[] }>(),
    'load documents fail': props<{ error: unknown }>(),

    'load documents statuses count': emptyProps(),
    'load documents statuses count success': props<{
      documentsStatusesCount: StatusState<typeof StatusFilter>
    }>(),
    'load documents statuses count fail': props<{ error: unknown }>(),

    'load submission': props<{ id: string }>(),
    'load submission success': props<{ document: Document }>(),
    'load submission fail': props<{ error: unknown }>(),

    'delete document': props<{ id: string }>(),
    'delete document success': emptyProps(),
    'delete document fail': props<{ error: string }>(),

    'awaiting document': props<{ id: string }>(),
    'awaiting document success': emptyProps(),
    'awaiting document fail': props<{ error: string }>(),

    'set total count': props<{ count: number }>(),
    'patch list filters': props<{ filters: DataFilters }>(),
    'set list filters': props<{ filters: DataFilters }>()
  }
})
