export type DataFilters = Partial<{
  search: string
  itemsPerPage: number
  page: number
  sortBy: string
  sortDirection: 'asc' | 'desc'
  status: StatusFilter
  containsInvoice: boolean
  uuid: string
}>

export enum StatusFilter {
  Unmatched = 'Unmatched',
  Awaiting = 'Awaiting'
}
