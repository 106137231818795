import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ON_REDACT_CALLBACK } from '../+state/documents.constants'
import { platform_v2_1 } from '@navix/services-api-models'

@Injectable({
  providedIn: 'root'
})
export class DocumentsViewService {
  private popup: Window | undefined | null
  private readonly DEFAULT_SCREEN_WIDTH = 1000 as const
  private readonly DEFAULT_SCREEN_HEIGHT = 800 as const
  private readonly DEFAULT_SCREEN_Y_POSITION = 100 as const
  private screenX = screen.width / 2 - this.DEFAULT_SCREEN_WIDTH / 2
  private screenY: number = this.DEFAULT_SCREEN_Y_POSITION
  private documentsURL = '/documents'
  windowOpened$ = new BehaviorSubject<null | boolean>(null)

  private updateScreenCoordinates(screenX: number, screenY: number) {
    this.screenX = screenX
    this.screenY = screenY
  }

  private buildWindowParams() {
    return `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
          width=${this.DEFAULT_SCREEN_WIDTH},height=${this.DEFAULT_SCREEN_HEIGHT},
          screenX=${this.screenX},screenY=${this.screenY},target=_self`
  }

  private openDocumentsWindow() {
    this.windowOpened$.next(true)
    const params = this.buildWindowParams()

    this.popup = open(this.documentsURL, 'Documents', params)

    if (this.popup === null) return

    const checkWindow = () => {
      if (this.popup && this.popup.closed) {
        this.windowOpened$.next(false)
        clearInterval(checkInterval)
      }
    }

    const checkInterval = setInterval(checkWindow, 1000)

    this.popup.addEventListener('visibilitychange', () => {
      this.setScreenPositionFromCurrentPopup()
    })
  }

  openInvoiceDocumentsInNewWindow(
    shipmentNumber: number | string,
    invoiceId: number,
    invoiceNumber: number | string,
    selectedIndex?: number
  ) {
    const invoice = {
      shipmentNumber,
      vendorInvoiceId: invoiceId,
      invoiceNumber
    }
    const data = btoa(JSON.stringify({ invoice, selected: selectedIndex }))
    this.setScreenPositionFromCurrentPopup()

    if (data) {
      this.documentsURL = `/documents?data=${data}`
    }

    this.openDocumentsWindow()
  }

  openStandaloneInvoiceDocumentsViewerInNewWindow(
    invoiceId: number,
    preselectedDocumentIndex?: number
  ) {
    this.setScreenPositionFromCurrentPopup()
    this.documentsURL = `/standalone/invoices/s/view-documents/${invoiceId}${
      preselectedDocumentIndex ? `?i=${preselectedDocumentIndex}` : ''
    }`
    this.openDocumentsWindow()
  }

  private setScreenPositionFromCurrentPopup() {
    if (this.popup === undefined || this.popup === null || this.popup.closed)
      return
    const { screenX, screenY } = this.popup
    this.updateScreenCoordinates(screenX, screenY)
  }

  openStandaloneInvoiceDocumentsRedactorInNewWindow(
    invoiceId: number,
    preselectedDocumentIndex?: number,
    options?: { onRedactCallback: () => void }
  ) {
    this.setScreenPositionFromCurrentPopup()

    this.documentsURL = `/standalone/invoices/s/redact-document/${invoiceId}${
      preselectedDocumentIndex ? `?i=${preselectedDocumentIndex}` : ''
    }`
    ;(window as any)[ON_REDACT_CALLBACK] = options?.onRedactCallback

    this.openDocumentsWindow()
  }

  openStandaloneUnmatchedDocumentsViewerInWindow(id: string) {
    this.setScreenPositionFromCurrentPopup()
    this.documentsURL = `/standalone/documents/s/view-documents/${id}`
    this.openDocumentsWindow()
  }

  openStandaloneUnmatchedDocumentsBasicInWindow(
    row: platform_v2_1.Navix_Services_Sdk_Document
  ) {
    const documents = [
      {
        typeDescription: row.type,
        name: row.attributes?.originalFilename ?? '',
        uuid: row.uuid ?? '',
        url: row.data.uri ?? ''
      }
    ]

    const data = btoa(JSON.stringify(documents))

    this.setScreenPositionFromCurrentPopup()
    this.documentsURL = `/standalone/documents/s/view-documents-basic/${data}`
    this.openDocumentsWindow()
  }
}
