import { makeEnvironmentProviders } from '@angular/core'
import { provideEffects } from '@ngrx/effects'
import { provideState } from '@ngrx/store'
import { DocumentsEffects } from '../+state/documents.effects'
import { DocumentsFacade } from '../+state/documents.facade'
import {
  DOCUMENTS_FEATURE_KEY,
  documentsReducer
} from '../+state/documents.reducer'

export function provideDocumentsFeature() {
  return makeEnvironmentProviders([
    provideEffects(DocumentsEffects),
    provideState(DOCUMENTS_FEATURE_KEY, documentsReducer),
    DocumentsFacade
  ])
}
