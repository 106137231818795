import {
  LoadingState,
  LoadingStatuses,
  getDefaultLoadingState,
  updateLoadingState
} from '@navix/shared/loading'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { DataFilters, StatusFilter } from '../domain/data-filters.model'
import { AsyncOperations } from '../domain/document-loading.model'
import { Document } from '../domain/document.model'
import { documentsActions } from './documents.actions'
import {
  StatusState,
  getDefaultDocumentStatusCountState
} from '../domain/get-documents-status-state'

export const DOCUMENTS_FEATURE_KEY = 'feature-documents'

export interface DocumentsState extends EntityState<Document> {
  loading: LoadingState<typeof AsyncOperations>
  filters: DataFilters
  selectedId?: string | number
  totalCount: number
  documentsStatusesCount: StatusState<typeof StatusFilter>
}

export interface DocumentsPartialState {
  readonly [DOCUMENTS_FEATURE_KEY]: DocumentsState
}

export const documentsAdapter: EntityAdapter<Document> =
  createEntityAdapter<Document>()

export const initialDocumentsState: DocumentsState =
  documentsAdapter.getInitialState({
    loading: getDefaultLoadingState(AsyncOperations),
    documentsStatusesCount: getDefaultDocumentStatusCountState(StatusFilter),
    totalCount: 0,
    filters: {
      search: undefined,
      itemsPerPage: 25,
      page: 1,
      sortBy: 'EmailDate',
      sortDirection: 'asc',
      status: StatusFilter.Unmatched,
      containsInvoice: undefined
    }
  })

const reducer = createReducer(
  initialDocumentsState,
  on(
    documentsActions.setTotalCount,
    (state, { count }): DocumentsState => ({
      ...state,
      totalCount: count
    })
  ),
  on(
    documentsActions.setListFilters,
    (state, { filters }): DocumentsState => ({
      ...state,
      filters
    })
  ),
  on(
    documentsActions.patchListFilters,
    (state, { filters }): DocumentsState => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters
      }
    })
  ),
  on(
    documentsActions.setLoading,
    (state, { operation, loading, message }): DocumentsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation,
        status: loading,
        message: message ?? ''
      })
    })
  ),
  on(
    documentsActions.loadDocuments,
    (state): DocumentsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getAll,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    documentsActions.loadDocumentsSuccess,
    (state, { documents }): DocumentsState =>
      documentsAdapter.setAll(documents, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getAll,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    documentsActions.loadDocumentsFail,
    (state): DocumentsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getAll,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load documents."
      })
    })
  ),
  on(
    documentsActions.loadDocumentsStatusesCount,
    (state): DocumentsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDocumentStatusesCount,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    documentsActions.loadDocumentsStatusesCountSuccess,
    (state, { documentsStatusesCount }): DocumentsState => ({
      ...state,
      documentsStatusesCount,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDocumentStatusesCount,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    documentsActions.loadDocumentsFail,
    (state): DocumentsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDocumentStatusesCount,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load documents statuses count."
      })
    })
  ),
  on(
    documentsActions.loadSubmission,
    (state: DocumentsState, { id }): DocumentsState => ({
      ...state,
      selectedId: id,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getSubmission,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    documentsActions.loadSubmissionSuccess,
    (state: DocumentsState, { document }): DocumentsState => ({
      ...documentsAdapter.upsertOne(document, state),
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getSubmission,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    documentsActions.loadSubmissionFail,
    (state: DocumentsState): DocumentsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getSubmission,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load document submission."
      })
    })
  ),
  on(
    documentsActions.setSelectedId,
    (state, { selectedId }): DocumentsState => ({ ...state, selectedId })
  )
)

export function documentsReducer(
  state: DocumentsState | undefined,
  action: Action
) {
  return reducer(state, action)
}
