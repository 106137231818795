import { Document } from '../domain/document.model'
import { GetDocumentsResponse } from '../domain/get-documents.response'

export function fromGetDocumentsResponse(
  response: GetDocumentsResponse
): Document[] {
  if (!Array.isArray(response.records)) return []
  return response.records.map<Document>(
    submission =>
      ({
        id: submission.uuid,
        emailFrom: submission.emailFrom,
        emailDate: isNaN(Date.parse(submission?.emailDate ?? ''))
          ? undefined
          : new Date(submission.emailDate as string),
        status: submission.status,
        subject: submission.subject,
        documents:
          submission.documents?.map<Document['documents'][number]>(item => ({
            typeDescription: item?.type ?? '',
            name: item.name,
            url: item.url
          })) || [],
        invoiceSubmissionResult: submission.invoiceFailedResults?.[0]?.data
      }) as Document
  )
}
