import { Injectable } from '@angular/core'
import { FileFilterHandler } from './FileFilterHandler'
import { filterByMaximumFileSize } from '../rules/filterByMaximumFileSize'
import { filterByMaximumFilesLength } from '../rules/filterByMaximumFilesLength'
import { filterByDocumentType } from '../rules/filterByDocumentType'
import { CuratedFileList } from '../domain/curated-file-list.model'

@Injectable({
  providedIn: 'root'
})
export class DocumentsInputService {
  private fileHandler: FileFilterHandler

  constructor() {
    this.fileHandler = new FileFilterHandler()
      .setNext(filterByMaximumFileSize)
      .setNext(filterByDocumentType)
      .setNext(filterByMaximumFilesLength)
  }

  getCuratedFiles(files: FileList): CuratedFileList {
    const filesArray = Array.from(files)
    return this.fileHandler.handle(filesArray)
  }

  formatBytes(bytes: number, decimals = 2) {
    //copied from legacy code
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
}
