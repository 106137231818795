import {
  MAX_FILE_SIZE_BYTE,
  MAX_FILE_SIZE_MB
} from '../+state/documents.constants'
import { CuratedFileList } from '../domain/curated-file-list.model'

export function filterByMaximumFileSize(files: File[]): CuratedFileList {
  const allowedFiles = files.filter(file => file.size <= MAX_FILE_SIZE_BYTE)
  const notAllowedFiles = files.filter(file => file.size > MAX_FILE_SIZE_BYTE)

  return {
    files: allowedFiles,
    errors: notAllowedFiles.map(file => ({
      filename: file.name,
      message: `${file.name} has exceeded ${MAX_FILE_SIZE_MB} MB. Please reduce the size and try again.`
    }))
  }
}
