const KB = 1024
const MB = KB * 1024
export const MAX_FILE_SIZE_MB = 5
export const MAX_FILE_SIZE_BYTE = MAX_FILE_SIZE_MB * MB // as 5242880
export const MAX_FILES_TO_UPLOAD = 10
export const ALLOWED_FILE_TYPES = ['application/pdf']
export const ALLOWED_FILE_TYPES_DESCRIPTIONS = ['PDF']

export const DOCUMENTS_ABSOLUTE_ROUTE_PREFIX =
  '/pre-audit/unmatched/documents/s' as const
export const DELETE_DOCUMENT_DIALOG_ID = 'DELETE_DOCUMENT_DIALOG_ID' as const
export const AWAITING_DOCUMENT_DIALOG_ID =
  'AWAITING_DOCUMENT_DIALOG_ID' as const
export const ON_REDACT_CALLBACK = 'onRedactCallback' as const
