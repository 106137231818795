import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import {
  API_URL,
  API_URL_V2_VERSION,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { Observable } from 'rxjs'
import { DataFilters, StatusFilter } from '../domain/data-filters.model'
import { GetDocumentsResponse } from '../domain/get-documents.response'

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiV2Url = inject(API_URL_V2_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly parser = inject<OdataParser>(QUERY_PARSER)
  private readonly apiDocumentsUrl = `${this.apiUrl}unmatched-document` as const
  private readonly apiDocuments2Url =
    `${this.apiV2Url}unmatched-document/` as const
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  private filterColumns = ['Subject', 'EmailFrom']

  private getQueryFilters = (filters: DataFilters, count = true) => {
    const query = this.parser
      .prepareWithoutEntity()
      .setCount(count)
      .setContainFilterColumns(this.filterColumns)

    if (filters.search) query.setContainFilter(filters.search)
    if (filters.itemsPerPage) query.setTop(filters.itemsPerPage)
    if (filters.itemsPerPage && filters.page)
      query.setSkip(filters.itemsPerPage * (filters.page - 1))
    if (filters.status === StatusFilter.Unmatched)
      query.setEqualityFilter('Status', StatusFilter.Unmatched)
    if (filters.status === StatusFilter.Awaiting)
      query.setEqualityFilter('Status', StatusFilter.Awaiting)
    if (filters.sortBy && filters.sortDirection)
      query.setOrderBy({
        column: filters.sortBy,
        direction: filters.sortDirection
      })
    return query
  }

  getUnmatchedSubmissionsV2 = (
    filters: DataFilters
  ): Observable<GetDocumentsResponse> => {
    const queryFilters: Record<
      string,
      string | number | boolean | ReadonlyArray<string | number | boolean>
    > = {}

    // Map filters to query parameters
    const filterMapping: Record<string, keyof DataFilters> = {
      pageNumber: 'page',
      pageSize: 'itemsPerPage',
      status: 'status',
      sortBy: 'sortBy',
      sortOrder: 'sortDirection',
      containsInvoice: 'containsInvoice',
      searchTerm: 'search',
      uuid: 'uuid'
    }

    Object.entries(filterMapping).forEach(([param, key]) => {
      const value = filters[key]
      if (value !== undefined) {
        queryFilters[param] = value
      }
    })

    const params = new HttpParams({ fromObject: queryFilters })

    return this._http.get<GetDocumentsResponse>(`${this.apiDocuments2Url}`, {
      params,
      headers: this.headersWithApiKey
    })
  }

  deleteDocument = (id: string): Observable<object> =>
    this._http.delete(`${this.apiDocumentsUrl}?submissionUuid=${id}`, {
      headers: { ...this.apiKey }
    })

  awaitDocument = (id: string): Observable<object> =>
    this._http.patch(`${this.apiDocumentsUrl}?submissionUuid=${id}`, {
      headers: { ...this.apiKey }
    })
}
